// Color system

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #2d2d2d;
$black:    #000000;

$yellow: #32f7d9;

$primary: $yellow;
$light: $white;
$dark: $gray-900;

// Body

$body-bg: #f7f7f7;

// Grid columns

$grid-gutter-width: 30px !default;

// Links

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 3.75 !default;
$h2-font-size: $font-size-base * 1.875 !default;

$subhead-font-size: $font-size-base * 1.125;

$link-color: $dark;
$link-hover-decoration: none !default;
$link-hover-color: $black;

// Typograpgy

$font-weight-bolder: 900 !default;
$font-weight-light: 300 !default;

$line-height-base: 1.15;
$line-height-lg: 1.15;
$line-height-sm: 1.15;

$small-font-size: 87.5%;

$font-family-sans-serif: 'Lato', sans-serif;
$font-family-cursive: 'Kaushan Script', cursive;

$headings-color: $gray-900 !default;

$text-muted: $gray-500 !default;

// Tables
$border-color: #E7E7E7;
$table-head-bg: #F4F4F4;
$table-head-color: $dark;

// Buttons + Forms

$input-btn-padding-y:         0.75rem;
$input-btn-padding-x:         1rem;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;

// Buttons

$btn-padding-y:               $input-btn-padding-y * 1.5;
$btn-padding-x:               $input-btn-padding-x * 1.5;
$btn-font-size:               .68rem;

$btn-padding-y-sm:            $input-btn-padding-y-sm * 4;
$btn-padding-x-sm:            $input-btn-padding-x-sm * 3;

$btn-padding-y-lg:            $input-btn-padding-y-lg * 4;
$btn-padding-x-lg:            $input-btn-padding-x-lg * 3;

$btn-border-radius:           0;
$btn-border-radius-lg:        0;
$btn-border-radius-sm:        0;

// Forms

$input-font-weight:           300;

$input-bg: #eeeeee;
$input-border-color: #eeeeee;

$input-border-radius: 0.15rem;
$input-border-radius-lg: $input-border-radius;
$input-border-radius-sm: $input-border-radius;

$input-placeholder-color: #c5c5c5;

$input-focus-border-color: $dark;
$input-focus-box-shadow: none;

$custom-control-indicator-border-color: #eeeeee;

$custom-file-text: (
    en: "Browse",
    fr: "Parcourir",
) !default;

// Breadcrumbs

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-margin-bottom: 2rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $white;
$breadcrumb-active-color: $white;


// Navs

$nav-link-padding-y: .8rem;

// Navbar

$navbar-padding-y: 0;
$navbar-padding-x: 0;

$navbar-nav-link-padding-x: 1.25rem;

$navbar-toggler-padding-y: .4rem;
$navbar-light-toggler-border-color: transparent;


// Alerts

$alert-bg-level: -12;

// Components

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$box-shadow: 0.8125rem 0 1.875rem rgba($black, 0.1);

$transition-base: all .2s ease-in-out !default;

// Card

$card-bg-hover: rgba($yellow, 0.8);
$card-border-color: $border-color;

// Pagination

$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $link-color !default;
$pagination-bg:                     $white !default;
$pagination-border-width:           0;
$pagination-border-color:           transparent !default;

$pagination-focus-box-shadow:       $box-shadow !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $yellow !default;
$pagination-hover-border-color:     transparent !default;

$pagination-active-color:           $white !default;
$pagination-active-bg:              $dark!default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $gray-300 !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  transparent !default;

// List

$list-group-border-color: $border-color;
